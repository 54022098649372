'use client'

import React from 'react'
import PropTypes from 'prop-types'

import 'normalize.css'
import 'components/homepage/sass/main.scss'
import 'components/homepage/style.css'
import useStage from 'utils/hooks/useStage'
import Logic from '@weidoapp/logic/web'

export default function RootLayout ({ children }) {
  useStage()

  return (
    <>
      { children }
      <Logic.Services />
    </>
  )
}

RootLayout.propTypes = {
  children: PropTypes.any
}
