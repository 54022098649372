export const DEV = process.env.devEnabled === '1'
export const ENC_PASSWORD = process.env.encodingPassword

export const DEFAULT_STAGE = 'dev'
export const STAGE = () => {
  try {
    return localStorage.getItem('stage') || 'dev'
  } catch (e) {
    return DEFAULT_STAGE
  }
}
export const HOST = 'weido.app'
export const SITE_URL = `https://${HOST}`

export const DEBUG = false // local console

// app settings
export const APP_NAME = 'Weido'

export const ROLE_MAP = {
  'super-admin': 'Superadminas',
  admin: 'Adminas',
  moderator: 'Moderatorius',
  user: 'Vartotojas'
}

export const STATIC_PAGES_STAGE = 'stag'
export const GA_TRACKING_ID = 'G-F33LGLW9ET'
export const SITE_TITLE = 'Weido'
