'use client'

import { useEffect } from 'react'

import { HOST, DEV, DEBUG } from 'config'
import Logic from '@weidoapp/logic/web'

export default function useStage () {
  useEffect(() => {
    const host = window.location.hostname
    if (DEBUG) console.info('DEV', DEV)
    if (DEBUG) console.info('host', host)
    const isProd = host === HOST
    if (!isProd && !DEV) {
      const stage = host.replace(`.${HOST}`, '')
      if (DEBUG) console.info('stage', stage)
      Logic.actionValid(stage, ['dev', 'stag'])
      localStorage.setItem('stage', stage)
    }

    if (DEV) {
      localStorage.setItem('stage', 'dev')
    }
  }, [])
}
